import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Jira from "../../../services/Jira";
import Loading from "../Loading";
import ErrorMessage from "../ErrorMessage";
import useErrorMessage from "../../hooks/useErrorMessage";
import "../../css/bootstrap.min.css";
import "../../css/custom.min.css";
import "../../css/styles.css";

ReactModal.setAppElement("#root");

async function getJiraEDADefectsInfo(
  token,
  component,
  endDate,
  productFieldId,
  productName,
  qaReason,
  startDate,
  bComponent,
  bQAreason
) {
  try {
    const resp = await Jira.getJiraEDADefectsInfo(
      token,
      component,
      endDate,
      productFieldId,
      productName,
      qaReason,
      startDate,
      bComponent,
      bQAreason
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting EDA defects (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getJiraDefectsInfo(
  token,
  projectId,
  createdDt,
  releaseName,
  status,
  severity
) {
  try {
    const resp = await Jira.getJiraDefectsInfo(
      token,
      projectId,
      createdDt,
      releaseName,
      status,
      severity
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting Jira defects (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function DefectsModal(props) {
  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(false);
  const [defectsInfo, setDefectsInfo] = useState([]);

  const titlesMap = {
    issueKey: "Issue ID",
    issueID: "Issue ID",
    issueType: "Issue Type",
    issueSummary:
      props.data.chart === "resolvedAndAssignedItems"
        ? "Issues Summary"
        : "Issue Summary",
    summary: props.data.chart !== "rootCauseAnalysis" ? "Issue Summary" : null,
    status: "Status",
    resolution: "Resolution",
    severity: "Severity",
    assignee: "Assignee",
    edaIssueKey: "EDA Issue",
    processAreaCatogery: "Process Area Category",
  };

  useEffect(() => {
    const handleDefects = async (e) => {
      var defects;
      if (props.data.eda) {
        defects = await getJiraEDADefectsInfo(
          props.token,
          props.data.component,
          props.data.endDate,
          props.data.productFieldId,
          props.data.productName,
          props.data.qaReason,
          props.data.startDate,
          props.data.bComponent,
          props.data.bQAreason
        );
      } else {
        defects = await getJiraDefectsInfo(
          props.token,
          props.data.projectId,
          props.data.createdDt,
          props.data.releaseName,
          props.data.status,
          props.data.severity
        );
      }

      if (defects?.errMsg) {
        setErrorMessage("Defects cannot be received: " + defects?.errMsg);
        setDefectsInfo([]);
      } else if (!defects || defects.length < 1) {
        setErrorMessage("Response does not contain any data.");
        setDefectsInfo([]);
      } else {
        setDefectsInfo(defects);
      }

      setLoading(false);
    };

    setErrorMessage("");
    if (props.isOpen) {
      if (props.data?.defects) {
        setDefectsInfo(props.data.defects);
      } else {
        setLoading(true);
        handleDefects();
      }
    }
  }, [props.data.projectId, props.isOpen]);

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        let splited = sortConfig.key.header.split(" ");
        sortableItems.sort((a, b) => {
          let sortWith = "";
          if (splited.length > 1) {
            splited.map((value, index) => {
              if (index === 0) value = value.toLowerCase();
              sortWith = sortWith + value;
            });
          } else {
            sortWith = sortConfig.key.header.toLowerCase();
          }

          const valueA = a[sortWith]?.toLowerCase() || "";
          const valueB = b[sortWith]?.toLowerCase() || "";

          if (valueA === "" && valueB !== "") return 1;
          if (valueB === "" && valueA !== "") return -1;

          if (valueA < valueB) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (valueA > valueB) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key.header === key.header &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const ModalWindow = () => {
    var title = props.data.title ? props.data.title : "";
    var headers = [];
    var rows = [];
    var indexes = [];

    const { items, requestSort, sortConfig } = useSortableData(defectsInfo);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key.header === name.header
        ? sortConfig.direction
        : undefined;
    };

    if (items && items.length > 0) {
      headers = Object.keys(items[0]).map((item, index) => {
        let header = titlesMap[item] ? titlesMap[item] : item;
        if (props.data.tableTitles.indexOf(header) >= 0) {
          indexes.push(index);
          return (
            <th key={index}>
              <button
                type="button"
                onClick={() => requestSort({ header })}
                className={getClassNamesFor({ header })}
              >
                {header}
              </button>
            </th>
          );
        }
        return null;
      });

      rows = items.map((item, index) => {
        return (
          <tr key={index}>
            {Object.keys(item).map((key, index) => {
              if (
                index === 0 ||
                (key === "edaIssueKey" &&
                  props.data.tableTitles.indexOf(titlesMap["edaIssueKey"]) >= 0)
              ) {
                return (
                  <td className="table-bordered text-nowrap" key={index}>
                    <a
                      href={item.selfLink}
                      className="link-primary"
                      fontSize="12px"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item[key]}
                    </a>
                  </td>
                );
              }
              if (indexes.indexOf(index) >= 0) {
                return (
                  <td className="table-bordered" key={index}>
                    {item[key]}
                  </td>
                );
              }
              return null;
            })}
          </tr>
        );
      });
    }

    const style = {
      content: {
        border: "4px",
        borderCcolor: "#000000",
        borderRadius: "4px",
        bottom: "auto",
        maxHeight: "80%",
        minHeight: "20rem",
        left: "50%",
        padding: "1rem",
        position: "fixed",
        right: "auto",
        top: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "20rem",
        width: "60%",
        maxWidth: "60rem",
        backgroundColor: "#e7eff8",
        boxShadow: "0 5px 15px rgba(0,0,0,.5)",
      },
    };

    const handleCloseClick = () => {
      props.setShowModal(false);
      setErrorMessage("");

      const scrollY = sessionStorage.getItem("scrollY");
      if (!scrollY || scrollY === "null" || scrollY === "undefined") {
        return;
      }
      window.scrollTo(0, Number(scrollY));
    };

    return (
      <ReactModal
        isOpen={props.isOpen}
        shouldFocusAfterRender={true}
        dialogClassName="modal-90w"
        shouldCloseOnOverlayClick={true}
        style={style}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleCloseClick()}
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <h5 className="modal-title" id="modalTitle">
            {title}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCloseClick()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          id="modal-body"
          className="modal-body"
          style={{
            minHeight: "10rem",
            maxHeight: "600px",
            overflowY: "auto",
            bottom: "0",
            top: "0",
            padding: "0",
          }}
        >
          <ErrorMessage errorMessage={errorMessage} />
          {loading ? (
            <Loading />
          ) : (
            <table
              className="table table-bordered table-hover table-sm "
              style={{
                backgroundColor: "#ffffff",
                height: "100%",
                top: 0,
                padding: 0,
                margin: 0,
                fontSize: "12px",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <tr className="table-primary">{headers}</tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          )}
        </div>
        <div className="modal-footer">
          <button
            className="btn  btn-primary  btn-block ms-4 "
            onClick={() => handleCloseClick()}
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </ReactModal>
    );
  };

  return <ModalWindow />;
}
