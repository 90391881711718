import React, { useEffect, useState } from "react";
import useProjectDetails from "../hooks/useProjectDetails";
import {
  MenuItem,
  Autocomplete,
  TextField,
  Tooltip,
  Chip,
} from "@mui/material";

const ADOTestManagementTool = ({
  editMode,
  ADORequestData,
  handleADOTestPlansReleases,
  ADO_Project_Selected,
  isDataLoading,
  testPlansOptions,
}) => {
  const { projectDetails } = useProjectDetails();
  const [disabled, setDisabled] = useState(false);
  const [isAllPopulated, setIsAllPopulated] = useState(false);
  const [testPlanReleasesOptions, setTestPlanReleasesOptions] = useState([]);
  const [selectedTestPlansReleases, setSelectedTestPlansReleases] = useState(
    []
  );

  const tooltipStyles = {
    tooltip: {
      sx: {
        backgroundColor: "#66b9ff",
        color: "#ffffff",
        fontSize: "12px",
      },
    },
    arrow: {
      sx: {
        color: "#66b9ff",
      },
    },
  };

  useEffect(() => {
    if (projectDetails && !editMode) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setTestPlanReleasesOptions(testPlansOptions);
  });

  useEffect(() => {
    if (projectDetails && testPlanReleasesOptions?.length) {
      handlePopulateFields();
    }
    // getProgramLabelForTestPlansOptions(testPlanReleasesOptions);
  }, [testPlanReleasesOptions]);

  useEffect(() => {
    handleADOTestPlansReleases(selectedTestPlansReleases);
  }, [selectedTestPlansReleases]);

  useEffect(() => {
    const temp_arr = [];
    selectedTestPlansReleases.map((item) => {
      if (ADO_Project_Selected.includes(item.program)) {
        temp_arr.push(item);
      }
    });
    setSelectedTestPlansReleases(temp_arr);
  }, [ADO_Project_Selected]);

  const handlePopulateFields = () => {
    let valuesToPopulate = [];
    let completeValuesToPopulate = [];

    projectDetails.lpAdoRelations?.forEach((project) => {
      if (project.adoTestPlanRelease !== null) {
        return project.adoTestPlanRelease.forEach((testPlan) => {
          valuesToPopulate.push(testPlan);
        });
      }
    });

    valuesToPopulate.forEach((value) => {
      testPlanReleasesOptions?.find((option) => {
        if (option.id === value) {
          completeValuesToPopulate.push(option);
        }
      });
    });

    setSelectedTestPlansReleases(completeValuesToPopulate);
  };

  const getProgramLabelForTestPlansOptions = (data) => {
    data.forEach((option) => {
      let programValue = option.program;
      let newName = `${option.name} - [${programValue}]`;

      option.name = newName;
    });

    return data;
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col md-6">
          <label htmlFor="testTool" className="form-label fs-7 fw-bold">
            <span style={isAllPopulated ? {} : { color: "red" }}>* </span>
            ADO Test Management:
          </label>
          {isDataLoading && (
            <div
              className="spinner-border text-secondary spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </div>
      </div>
      <div
        className="row mb-4"
        style={
          isDataLoading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <div className="col md-6">
          <ul className="list-group input-group-sm">
            <div className="input-group input-group-sm">
              <li className="list-group-item" style={{ width: "50%" }}>
                <div
                  className="input-group input-group-sm"
                  style={{ flexWrap: "nowrap" }}
                >
                  <span className="input-group-text" id="basic-addon3">
                    ADO Test Plan/Releases:
                  </span>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      paddingBlock: "5px",
                    }}
                  >
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      freeSolo
                      disableCloseOnSelect
                      disabled={
                        isDataLoading || disabled || ADORequestData.length < 1
                      }
                      noOptionsText={"There are no options available."}
                      value={selectedTestPlansReleases || []}
                      options={testPlanReleasesOptions || []}
                      groupBy={(option) => option.program}
                      getOptionLabel={(option) => option.name}
                      onChange={(_, value) => {
                        setSelectedTestPlansReleases(value);
                        setIsAllPopulated(true);

                        if (!value.length) {
                          setIsAllPopulated(false);
                          setSelectedTestPlansReleases([]);
                        }
                      }}
                      sx={{
                        "& .MuiInputBase-root .MuiAutocomplete-tag": {
                          background: "#0096fb",
                          color: "#ffffff",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#ffffff",
                          fontSize: "16px",
                        },
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#ffffff!important",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Test Plan/Releases"
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Tooltip title={option.name} arrow>
                          <MenuItem
                            dense
                            key={option.id}
                            sx={{ fontSize: "14px" }}
                            {...props}
                          >
                            {option.name}
                          </MenuItem>
                        </Tooltip>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Tooltip
                            key={option.id}
                            title={option.name}
                            placement="right"
                            arrow
                            disableFocusListener
                            disableTouchListener
                            componentsProps={tooltipStyles}
                          >
                            <Chip
                              label={option.name}
                              {...getTagProps({ index })}
                              sx={{
                                background: "#0096fb",
                                color: "#ffffff",
                                ".MuiChip-deleteIcon": {
                                  color: "#ffffff!important",
                                },
                              }}
                            />
                          </Tooltip>
                        ))
                      }
                    />
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ADOTestManagementTool;
