import React, { useState, useRef } from "react";
import { Chart } from "react-google-charts";

const RootCauseAnalysisChart = (props) => {
  const [data, setData] = useState(props?.data?.data);

  let issueMap = props?.data?.data?.issueMap;
  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();
  let currentIterationRef = null;

  currentIterationRef =
    props.currentIteration === "Overall"
      ? props.data?.data?.rootCause && props.data.data?.rootCause["Overall"]
      : props.data?.data?.rootCause &&
        props.data.data?.rootCause[props.currentIteration];

  const dataTable = [["Items", "Quantity"]];
  let rootCauseAnalysisItemsHeaders = Object.keys(currentIterationRef || {});
  currentIterationInfoRef.current = currentIterationRef;
  currentDataTableRef.current = dataTable;

  let quantityPerKey = {};

  for (const key in currentIterationRef) {
    const elements = currentIterationRef[key];
    quantityPerKey[key] = elements.length;
  }

  rootCauseAnalysisItemsHeaders.forEach((item) => {
    dataTable.push([item, quantityPerKey[item]]);
  });

  const chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row + 1;
          let selectedSeverity = currentDataTableRef.current[row][0];

          var defectTitles = [
            "Issue ID",
            "Issue Summary",
            "Issue Type",
            "Status",
            "Resolution",
            "Severity",
            "Assignee",
          ];
          var defectsIdsArray =
            currentIterationInfoRef.current?.[selectedSeverity];
          defectsIdsArray = defectsIdsArray ? defectsIdsArray : [];
          var defectsInfoArray = [];

          defectsIdsArray.forEach((key) => {
            if (!issueMap) {
              return;
            }
            if (issueMap[key]) {
              defectsInfoArray.push(issueMap[key]);
            }
          });

          defectsInfoArray.forEach((issue) => {
            if (issue.hasOwnProperty("lastUpdDate")) {
              delete issue.lastUpdDate;
            }
          });

          props.setModalData({
            title: `Root Cause Analysis: ${currentDataTableRef.current[row][0]}`,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: null,
            status: null,
            severity: currentDataTableRef.current[row][0],
            tableTitles: defectTitles,
            defects: defectsInfoArray,
            chart: "rootCauseAnalysis",
          });

          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  const options = {
    title: "Root Cause Analysis",
    titleTextStyle: { fontSize: "16" },
    chartArea: { left: 70, top: 50, width: "100%", height: "100%" },
    is3D: true,
    pieSliceTextStyle: {
      fontSize: 11,
    },
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={dataTable}
      options={options}
      chartEvents={chartEvents}
    />
  );
};

export default RootCauseAnalysisChart;
