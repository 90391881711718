import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";

function createData(array, object_count) {
  const tempArray = [];
  const tempOverAll = [];
  array.map((item) => {
    if (item[0] == "Overall") {
      return;
    }
    const tempSubItem = [];
    const defects = Object.entries(item[1]);
    defects.map((subItem) => {
      const tempObject = {
        jiraID: subItem[1].issueID,
        jiraDescription: subItem[1].issueSummary,
        jiraStatus: subItem[1].status,
        defectsAmount: subItem[1].amountOfDefect,

        defectsListInfo: subItem[1].defectInfoList,
        selfLink: subItem[1].selfLink,
      };
      tempSubItem.push(tempObject);
    });
    const tempItem = {
      sprintName: item[0],
      sprintStatus: object_count[item[0]]?.Status,
      totalAmountDefects: object_count[item[0]]["Total Amount"],
      sprintData: tempSubItem,
    };
    tempArray.push(tempItem);
  });
  array.map((subItem) => {
    if (subItem[0] == "Overall") {
      Object.values(subItem[1]).map((defect) => {
        const tempOverAllItem = {
          jiraID: defect.issueID,
          jiraDescription: defect.issueSummary,
          defectsAmount: defect.amountOfDefect,
          jiraStatus: defect.status,
          defectsListInfo: defect.defectInfoList,
          selfLink: defect.selfLink,
        };
        tempOverAll.push(tempOverAllItem);
      });
    }
  });
  return { perSprint: tempArray, simpleList: tempOverAll };
}

function Row(props) {
  const [open, setOpen] = React.useState(false);
  const handleModalOpening = (defectsListInfo, id) => {
    props.setModalList(defectsListInfo);
    props.setDefectListTitle(id);
    props.handleOpen(true);
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ background: "white" }}
      >
        <TableCell component="th" scope="row" align="center">
          <div className="fw-bolder">{props.row.sprintName}</div>
        </TableCell>
        <TableCell align="center">
          <div className="fw-bolder">{props.row.sprintStatus}</div>
        </TableCell>
        <TableCell align="center">
          <div className="fw-bolder">{props.row.totalAmountDefects}</div>
        </TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 3 }}>
              <Table size="small">
                <TableHead style={{ background: "#dae8f5" }}>
                  <TableRow>
                    <TableCell className="fw-bolder">Story ID</TableCell>
                    <TableCell className="fw-bolder">
                      Story Description
                    </TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Status
                    </TableCell>
                    <TableCell className="fw-bolder" align="center">
                      Number of Defects
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ background: "#f0f4f5" }}>
                  {props.row?.sprintData.map((historyRow) => (
                    <TableRow key={historyRow.jiraID}>
                      <TableCell component="th" scope="row">
                        <a
                          href={historyRow.selfLink}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {historyRow.jiraID}
                        </a>
                      </TableCell>
                      <TableCell>{historyRow.jiraDescription}</TableCell>
                      <TableCell align="center">
                        {historyRow.jiraStatus}
                      </TableCell>
                      <TableCell align="center">
                        {historyRow.defectsAmount > 0 ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleModalOpening(
                                historyRow.defectsListInfo,
                                historyRow.jiraID
                              )
                            }
                          >
                            {historyRow.defectsAmount}
                          </div>
                        ) : (
                          <div
                            style={{ cursor: "not-allowed", color: "#cacccf" }}
                          >
                            {historyRow.defectsAmount}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({ dataForTable }) {
  const [rows, setRows] = useState([]);
  const [simpleList, setSimpleList] = useState([]);
  const [filter, setFilter] = useState("filtered");
  const [defectListTitle, setDefectListTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [modalList, setModalList] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxHeight: "80%",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  useEffect(() => {
    const result = createData(
      Object.entries(dataForTable?.defectPerStoryMap),
      dataForTable?.defectCountPerStoryMap
    );
    setRows(result.perSprint);

    setSimpleList(result.simpleList);
  }, []);

  const handleFilterChange = (event, newFilter) => {
    setFilter(newFilter);
  };

  return (
    <TableContainer component={Paper}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3
            id="modal-modal-title"
            style={{ color: "#1675e0", marginLeft: "0.5rem" }}
          >
            {`Defects from ${defectListTitle}`}
          </h3>

          <Box sx={{ margin: 3 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="fw-bolder">Jira ID</TableCell>
                  <TableCell className="fw-bolder">Jira Description</TableCell>
                  <TableCell className="fw-bolder" align="center">
                    Type
                  </TableCell>
                  <TableCell className="fw-bolder" align="center">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {modalList.map((historyRow) => (
                  <TableRow key={historyRow.issueID}>
                    <TableCell component="th" scope="row">
                      <a
                        href={historyRow.selfLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {historyRow.issueID}
                      </a>
                    </TableCell>
                    <TableCell>{historyRow.issueSummary}</TableCell>
                    <TableCell align="center">{historyRow.issueType}</TableCell>
                    <TableCell align="center">{historyRow.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginLeft: "90%" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      <Table aria-label="collapsible table">
        <TableHead
          className="my-4"
          style={{
            background: "#F0F8FF",
            position: "relative",
            height: "90px",
          }}
        >
          <TableRow>
            {" "}
            <TableCell align="center">
              {filter == "filtered" ? <h5>Sprint</h5> : <h5></h5>}
            </TableCell>
            <TableCell align="center">
              {filter == "filtered" ? <h5>Sprint Status</h5> : <h5></h5>}
            </TableCell>
            <TableCell align="center">
              {filter == "filtered" ? <h5>Number of Defects</h5> : <h5></h5>}
            </TableCell>
            <ToggleButtonGroup
              value={filter}
              exclusive
              onChange={handleFilterChange}
              style={{
                minWidth: "200px",
                position: "absolute",
                top: "2px",
                right: "4px",
              }}
            >
              <Box className="my-4">
                <ToggleButton value="filtered">Sprints</ToggleButton>
                <ToggleButton value="no-filtered">Overall</ToggleButton>
              </Box>
            </ToggleButtonGroup>
            {/* </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {filter == "filtered" ? (
            rows.map((row) => (
              <Row
                key={row.name}
                row={row}
                setModalList={setModalList}
                handleOpen={handleOpen}
                handleClose={handleClose}
                setDefectListTitle={setDefectListTitle}
              />
            ))
          ) : (
            <SimpleList
              sprintData={simpleList}
              setModalList={setModalList}
              setDefectListTitle={setDefectListTitle}
              handleOpen={handleOpen}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const SimpleList = ({
  sprintData,
  setModalList,
  handleOpen,
  setDefectListTitle,
}) => {
  const handleModalOpening = (defectsListInfo, id) => {
    setModalList(defectsListInfo);
    setDefectListTitle(id);
    handleOpen(true);
  };

  // Ordenar los datos por defectos de manera descendente
  const sortedSprintData = sprintData
    ?.slice()
    .sort((a, b) => b.defectsAmount - a.defectsAmount);

  return (
    <Box sx={{ margin: 3 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className="fw-bolder">Jira ID</TableCell>
            <TableCell className="fw-bolder">Jira Description</TableCell>
            <TableCell className="fw-bolder" align="center">
              Status
            </TableCell>
            <TableCell className="fw-bolder" align="center">
              Number of Defects
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSprintData?.map((historyRow) => (
            <TableRow key={historyRow.jiraID}>
              <TableCell component="th" scope="row">
                <a
                  href={historyRow.selfLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {historyRow.jiraID}
                </a>
              </TableCell>
              <TableCell>{historyRow.jiraDescription}</TableCell>
              <TableCell align="center">{historyRow.jiraStatus}</TableCell>
              <TableCell align="center">
                {historyRow.defectsAmount > 0 ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleModalOpening(
                        historyRow.defectsListInfo,
                        historyRow.jiraID
                      )
                    }
                  >
                    {historyRow.defectsAmount}
                  </div>
                ) : (
                  <div style={{ cursor: "not-allowed", color: "#cacccf" }}>
                    {historyRow.defectsAmount}
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
