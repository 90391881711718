import FetchUtils from "./FetchUtils";

class Jira {
  static async getJiraInfo(token) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_ADAPTER_URL + "/jira/projects",
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Jira projects (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getIssueBacklogIfo(token, jiraProjectKey) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/project-data/open-issues-backlog?projectId=" +
          jiraProjectKey,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Jira projects (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getReleaseScopeInfo(token, jiraProjectKey) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-projects/requirements-release-scope?projectId=" +
          jiraProjectKey,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Jira projects (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getDefectByCategoryDates(token, projectType) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (!projectType || projectType.length < 1) {
        return { errMsg: "Project type is not selected!" };
      }
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        `/cross-project/issue-backlog-Defect-By-Category/${projectType}`;
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.statusCode !== 200) {
        let errorMsg =
          "Jira Custom info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        data.errMsg = errorMsg;
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira Custom info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getDefectByCategoryDataWithDates(
    token,
    projectType,
    startDate,
    endDate
  ) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (!projectType || projectType.length < 1) {
        return { errMsg: "Project type is not selected!" };
      }
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        `/cross-project/issue-backlog-date-range/${projectType}/${startDate}/${endDate}`;
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.statusCode !== 200) {
        let errorMsg =
          "Jira Custom info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        data.errMsg = errorMsg;
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira Custom info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRootCauseAnalysisInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        `${process.env.REACT_APP_JIRA_SERVICE_URL}/sterco-defects/root-cause/${projectId}`,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Jira projects (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getDefectsPerStoryPerSpring(token, jiraProjectKey) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/jira/defect-count-per-story/" +
          jiraProjectKey,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Jira projects (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTargetReleaseFields(token, jiraProjectKey) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_ADAPTER_URL +
          "/jira/" +
          jiraProjectKey +
          "/fields",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Jira Target Release fields  (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getCustomFieldValues(token, jiraProjectKey, customFieldId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/jira/" +
          jiraProjectKey +
          "/fields",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Jira field values (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getcustomFieldAndProjects(token, jiraProjectKey) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/project-data/release-scope?projectKey=" +
          jiraProjectKey +
          "&issueType=Bug&customField=customfield_14397",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Jira field values (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRequirementsStatusTrendingIfo(token, jiraProjectKey) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/jira/requirement-status-trending/" +
          jiraProjectKey,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Jira field values (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
  static async getcustomFieldReleaseSafeProject(
    token,
    jiraProjectKey,
    safeProject
  ) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/project-data/release-scope-safe-project?projectId=" +
          jiraProjectKey +
          "&safeProject=" +
          safeProject +
          "&safeProject=21074%20Apollo%20CCHC%20AnswerConnect%20Smart%20Charts",
        //jiraProjectKey,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Jira field values (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraChartsInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let promises = [];
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL + "/jira/" + projectId,
          "GET",
          headers
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL +
            "/jira/defect-current-release/" +
            projectId,
          "GET",
          headers
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL +
            "/jira/targeted-open-defects/" +
            projectId,
          "GET",
          headers
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL +
            "/project-data/items-resolved-status?projectId=" +
            projectId,
          "GET",
          headers
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL +
            "/project-data/resolved-assigned-items?projectId=" +
            projectId,
          "GET",
          headers
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL +
            "/project-data/defect-churn?projectId=" +
            projectId,
          "GET",
          headers
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_JIRA_SERVICE_URL +
            "/project-data/qa-defects-found?projectId=" +
            projectId,
          "GET",
          headers
        )
      );

      let responses = await Promise.all(promises);
      let responseData = {
        defectCurrentReleaseInfo: {},
        targetedOpenDefectsInfo: {},
        itemsWithResolvedStatus: {},
        resolvedAndAssignedItems: {},
        defectChurn: {},
        qaDefectsFound: {},
      };
      let i = 0;
      for (let data of responses) {
        if (!data || data.status !== "OK") {
          let errorMsg =
            "Jira charts info cannot be taken from the server: " +
            (data?.message
              ? data.message
              : data.error
              ? data.error
              : "Unexpected server error");
          return { errMsg: errorMsg };
        }
        responseData.projectId = projectId;
        for (let key in data.data) {
          let value = data.data[key];
          if (i === 1) {
            responseData["defectCurrentReleaseInfo"][key] = value;
            continue;
          }
          if (i === 2) {
            responseData["targetedOpenDefectsInfo"][key] = value;
            continue;
          }
          if (i === 3) {
            responseData["itemsWithResolvedStatus"][key] = value;
            continue;
          }
          if (i === 4) {
            responseData["resolvedAndAssignedItems"][key] = value;
            continue;
          }
          if (i === 5) {
            responseData["defectChurn"][key] = value;
            continue;
          }
          if (i === 6) {
            responseData["qaDefectsFound"][key] = value;
            continue;
          }
          responseData[key] = value;
        }
        i++;
      }
      return responseData;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraDefectsInfo(
    token,
    projectId,
    createdDt,
    releaseName,
    status,
    severity
  ) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      status = !status || status.toLowerCase() === "all" ? "null" : status;
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL + "/jira/defects/" + projectId;
      url += "?createdDt=" + (createdDt ? createdDt : "null") + "&";
      url += "releaseName=" + (releaseName ? releaseName : "null") + "&";
      url += "status=" + status + "&";
      url += "severity=" + (severity ? severity : "null");

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira defects info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      data.data.projectId = projectId;

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira defects info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraEDAInfo(token, projectTypeName, status) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (!projectTypeName || !projectTypeName[0]?.projectTypeName) {
        return { errMsg: "EDA info cannot be got for the empty project type!" };
      }

      let url = status
        ? process.env.REACT_APP_JIRA_SERVICE_URL +
          "/jira/escaped-defects/" +
          projectTypeName[0].projectTypeName +
          `?statuses=${status}`
        : process.env.REACT_APP_JIRA_SERVICE_URL +
          "/jira/escaped-defects/" +
          projectTypeName[0].projectTypeName;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira EDA info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting EDA info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraEDADefectsInfo(
    token,
    component,
    endDate,
    productFieldId,
    productName,
    qaReason,
    startDate,
    bComponent,
    bQAreason
  ) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      bComponent = bComponent ? bComponent : false;
      bQAreason = bQAreason ? bQAreason : false;

      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/jira/escaped-defects/details?";
      url += "component=" + encodeURIComponent(component) + "&";
      url += "endDate=" + encodeURIComponent(endDate) + "&";
      url += "productFieldId=" + encodeURIComponent(productFieldId) + "&";
      url += "productName=" + encodeURIComponent(productName) + "&";
      url += "qaReason=" + encodeURIComponent(qaReason) + "&";
      url += "startDate=" + encodeURIComponent(startDate) + "&";
      url += "bComponent=" + bComponent + "&";
      url += "bQAreason=" + bQAreason + "&";

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "EDA defects info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira defects info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRequirementCoverageJiraInfo(token, qTestIdArr) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/jira/defects?issueKeys=" +
        qTestIdArr;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira Requirements Coverage info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Requirement Coverage Jira info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraChartsForReportInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL + "/jira/" + projectId,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira Requirements Coverage info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira Report info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraSteercoDefectsInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL + "/sterco-defects/" + projectId,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira Steerco Open Defects By Serverity info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        data.data.errMsg = errorMsg;
      }

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira Report info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProcessComplianceTestIssuesInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/process-compliance/issues/" +
        projectId;
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira Process Compliance Test Issues info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Process Compliance Test Issues info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProjectTrackerDefectSummaryInfo(token, projectIds) {
    try {
      if (!projectIds || projectIds.length < 1) {
        return { message: "List of selected releases is empty!" };
      }
      let index = 0;
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/project-tracker/defect-summary?";
      for (let projectId of projectIds) {
        url += "projectId=" + projectId;
        if (index < projectIds.length - 1) {
          url += "&";
        }
        index++;
      }
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectIds " +
          projectIds +
          "Project Tracker LatestSprint info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Project Tracker LatestSprint: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getDefectAnaliticsChartsInfo(token, projectIds) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (!projectIds || projectIds.length < 1) {
        return { errMsg: "No projects selected!" };
      }
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/cross-project/defect-analytics?";
      projectIds.forEach((id) => (url += "projectId=" + id + "&"));

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.statusCode !== 200) {
        let errorMsg =
          "Defect Analitics info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        data.errMsg = errorMsg;
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Defect Analitics info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getJiraCustomInfo(token, projectType) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (!projectType || projectType.length < 1) {
        return { errMsg: "Project type is not selected!" };
      }
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/cross-project/jira-custom?projectType=" +
        projectType;
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.statusCode !== 200) {
        let errorMsg =
          "Jira Custom info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        data.errMsg = errorMsg;
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira Custom info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getIssueBacklogRegression(token, projectType) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      if (!projectType || projectType.length < 1) {
        return { errMsg: "Project type is not selected!" };
      }
      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/cross-project/issue-backlog/" +
        projectType;
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.statusCode !== 200) {
        let errorMsg =
          "Jira Custom info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        data.errMsg = errorMsg;
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Jira Custom info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRequirementAverageAgeJiraInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let url =
        process.env.REACT_APP_JIRA_SERVICE_URL +
        "/jira/requirement-average-age/" +
        projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira Requirements Average Age info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Requirement Requirements Average Age Jira info (" +
          err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
}

export default Jira;
