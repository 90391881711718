import React from "react";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Jira from "../../services/Jira";

async function getReleaseScopeInfo(token, jiraProjectKey) {
  try {
    let finalData = await Jira.getReleaseScopeInfo(token, jiraProjectKey).then(
      (res) => res
    );
    return finalData.data;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

async function getCustomFieldValues(token, jiraProjectKey) {
  try {
    let finalData = await Jira.getcustomFieldAndProjects(
      token,
      jiraProjectKey
    ).then((res) => res);
    return finalData;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

const ReleaseScopePage = ({
  currentProjectName,
  changeStyle,
  currentProjectId,
  token,
}) => {
  const [sprint, setSprint] = useState("");
  const [releaseScopeInfo, setReleaseScopeInfo] = useState("");
  const [sprintList, setSprintList] = useState([]);
  const [filteredSprintList, setFilteredSprintList] = useState([]);
  const [filteredSprintListOnSecondTable, setFilteredSprintListOnSecondTable] =
    useState([]);
  const [customfields, setCustomfields] = useState([]);
  const [safeProjectList, setSafeProjectList] = useState([]);
  const [safeProject, setSafeProject] = useState("");
  const [secondListOfSprings, setSecondListOfSprings] = useState([]);
  const [sprintSecondTable, setSprintSecondTable] = useState("");

  useEffect(() => {
    const handleReleaseScopeInfo = async () => {
      let result = await getReleaseScopeInfo(token, currentProjectId);
      let projectKey = result[0]?.jiraBLRId
        ? result[0]["jiraBLRId"].split("-")[0]
        : "";
      let customfieldsInfo = await getCustomFieldValues(token, projectKey);
      setReleaseScopeInfo(result);
      addItemToTheSprintList(result);
      setFilteredSprintList(result);
      setCustomfields(customfieldsInfo?.jiraSafeProjectList);
    };
    handleReleaseScopeInfo();
    changeStyle("releaseScope");
  }, []);

  useEffect(() => {
    handleFetch();
  }, [filteredSprintList]);

  const handleFetch = async () => {
    let res = await Jira.getcustomFieldReleaseSafeProject(
      token,
      currentProjectId,
      safeProject
    );
    const templistOfSprins = [];
    res.map((item) => {
      if (item.sprints[0] !== undefined) {
        templistOfSprins.push(item.sprints[0]);
      }
    });
    res.forEach((item) => {
      filteredSprintList.forEach((issue) => {
        if (issue.jiraBLRId === item.key) {
          issue.match = true;
          item.match = true;
        }
      });
    });
    addItemToTheSprintList(res, true);
    setSafeProjectList(res);
    setFilteredSprintListOnSecondTable(
      res.sort((p1, p2) => (p1.key < p2.key ? 1 : p1.key > p2.key ? -1 : 0))
    );
  };

  const buldingItemsList = (id, name, sprint, status, match = false) => {
    const matchClasses = "bg-warning bg-opacity-50";
    const notMatchclasses = "";

    if (id === "") return;
    if (status === "Second") {
      return (
        <tr className={match ? matchClasses : notMatchclasses}>
          <td>{id}</td>
          <td>{name}</td>
          <td>{sprint}</td>
        </tr>
      );
    }
    return (
      <tr className={match ? matchClasses : notMatchclasses}>
        <td>{id}</td>
        <td>{name}</td>
        <td>{sprint}</td>
        <td>{status}</td>
      </tr>
    );
  };

  const handleSprintChange = (event) => {
    setSprint(event.target.value);
    if (event.target.value === "All") {
      setFilteredSprintList(releaseScopeInfo);
      return;
    }
    const tempArray = [];
    releaseScopeInfo.forEach((item) => {
      if (item.sprint && item.sprint[0] === event.target.value) {
        tempArray.push(item);
      }
      setFilteredSprintList(tempArray);
    });
  };

  const handleSprintChangeOnSecondTable = (event) => {
    setSprintSecondTable(event.target.value);
    if (event.target.value === "All") {
      setFilteredSprintListOnSecondTable(safeProjectList);
      return;
    }
    const tempArraySecondTable = [];

    safeProjectList.forEach((item) => {
      if (item.sprints[0] === event.target.value) {
        tempArraySecondTable.push(item);
      }
    });
    setFilteredSprintListOnSecondTable([...new Set(tempArraySecondTable)]);
  };

  const addItemToTheSprintList = (list, second = false) => {
    const tempArray = [];

    if (second) {
      list.forEach((element) => {
        if (element.sprints !== null && element.sprints[0] !== undefined) {
          tempArray.push(element.sprints[0]);
        }
      });
      tempArray.sort((a, b) => {
        const aDate = new Date(a.split(" ")[1]);
        const bDate = new Date(b.split(" ")[1]);
        return bDate - aDate;
      });

      setSecondListOfSprings([...new Set(tempArray)]);
      return;
    }

    list.forEach((element) => {
      if (element.sprint !== null && element.sprint[0] !== undefined) {
        tempArray.push(element.sprint[0]);
      }
    });
    tempArray.sort((a, b) => {
      const aDate = new Date(a.split(" ")[1]);
      const bDate = new Date(b.split(" ")[1]);
      return bDate - aDate;
    });

    setSprintList([...new Set(tempArray)]);
  };

  return (
    <div className=" p-4">
      <div className="row border-bottom mb-4">
        <h4>Release Scope</h4>
      </div>
      <div className="row">
        <div className="col">
          <div
            className=" text-light p-2 border-bottom"
            style={{ background: "#428bca" }}
          >
            qTest Release Scope
          </div>
          <table className="table border border-2">
            <thead className="text-light" style={{ background: "#428bca" }}>
              <tr>
                <th>Requirement ID</th>
                <th>Name</th>
                <th>
                  {" "}
                  <FormControl
                    variant="filled"
                    className="bg-light rounded"
                    fullWidth
                    defaultValue={"All"}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Sprint
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sprint}
                      label="Sprint"
                      onChange={handleSprintChange}
                    >
                      <MenuItem value={"All"}>All</MenuItem>
                      {sprintList.map((sprint) => {
                        return <MenuItem value={sprint}>{sprint}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </th>
                <th>Status</th>
              </tr>
            </thead>
            {releaseScopeInfo.length > 0 ? (
              filteredSprintList.map(
                ({ documentKey, name, sprint, jiraStatus, match }) => {
                  return buldingItemsList(
                    documentKey,
                    name,
                    sprint && sprint[0],
                    jiraStatus,
                    match
                  );
                }
              )
            ) : (
              <tr>
                <td>No data</td>
              </tr>
            )}
          </table>
        </div>
        <div className="col">
          <div
            className=" text-light ps-2 pt-2 row"
            style={{ background: "#428bca" }}
          >
            Stories from Jira
            <span className="col d-flex">
              <p style={{ width: "auto" }}>
                {" Safe-Target Release in " + currentProjectName}
              </p>
            </span>
          </div>
          <div className="row">
            <table className="table  border border-2">
              <thead className="text-light" style={{ background: "#428bca" }}>
                <tr>
                  <th>Key</th>
                  <th>Summary</th>
                  <th>
                    {" "}
                    <FormControl
                      variant="filled"
                      className="bg-light rounded"
                      fullWidth
                      defaultValue={"All"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Sprint
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sprintSecondTable}
                        label="Sprint"
                        onChange={handleSprintChangeOnSecondTable}
                      >
                        <MenuItem value={"All"}>All</MenuItem>
                        {secondListOfSprings.map((item) => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  </th>
                </tr>
              </thead>
              {safeProjectList.length > 1 ? (
                filteredSprintListOnSecondTable.map(
                  ({ key, summary, sprints, match }) => {
                    return buldingItemsList(
                      key,
                      summary,
                      sprints[0],
                      "Second",
                      match
                    );
                  }
                )
              ) : (
                <tr>
                  <td>No data, please change filters</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleaseScopePage;
